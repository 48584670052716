.wrapper-content {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: 50px 0;
  flex-wrap: wrap;
}

.banner {
  padding: 40px;
  text-align: center;
  width: 60%;
  flex: 1 1 45%; /* Adjust width */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner-content {
  max-width: 600px;
  margin: 0 auto;
  flex: 1;
}

h1.display-1.text-white.mb-md-4.animated.zoomIn {
  font-size: 70px;
}

p.text-white.animated.zoomIn.center-text {
  font-size: 1.25rem;
  background-color: #0488f4;
  border-radius: 30px;
  padding: 15px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-caption h1 {
  font-size: 2.5rem;
}

.carousel-caption img {
  max-height: 200px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.logo {
  height: 150px;
}

.text-image {
  height: 100px;
  margin-bottom: 10px;
}

p.text-blue.mb-0 {
  background-color: rgb(4, 136, 244);
  color: #fff;
  width: 50%;
  border-radius: 20px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

@media (min-width: 375px) { 
  .d-flex.justify-content-between.text-white.mb-3 {
      font-size: 15px;
  }
  p.mb-0.mt-1 {
      font-size: 14px;
  }
  h5.text-white.mb-0.mt-2 {
      font-size: 14px;
  }
}

@media (min-width: 425px) {
  img.me-2 {
      margin-left: 112px !important;
  }
}

@media (min-width: 768px) {
  .carousel-caption h1 {
      font-size: 40px;
  }

  .carousel-caption .logo {
      height: 161px;
      margin-top: -37px;
  }

  .carousel-caption .text-image {
      height: 100px;
  }

  .carousel-caption p.center-text {
      font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .carousel-caption h1 {
      font-size: 4.4rem;
  }

  .carousel-caption .logo {
      height: 250px;
  }

  .carousel-caption .text-image {
      height: 150px;
  }

  .carousel-caption p.center-text {
      font-size: 2rem;
  }
}

.banner-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.banner-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.subscribe-form,
.explore-btn {
  flex: 0 0 auto;
}

.subscribe-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Add margin to separate the form from the button */
}

.subscribe-form input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
}

.explore-btn {
  padding: 10px 20px;
  background-color: #de3233;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.explore-btn:hover {
  background-color: #a82020;
  color: white;
}

.refreshed {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}

.animated-item {
  display: block;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  animation: BottomTotop 6s linear infinite 0s;
}

.animated-item:nth-child(2n+2) {
  animation-delay: 2s;
}

.animated-item:nth-child(3n+3) {
  animation-delay: 4s;
}

@keyframes BottomTotop {
  0% {
      opacity: 0;
  }
  5% {
      opacity: 0;
      transform: translateY(5px);
  }
  10% {
      opacity: 1;
      transform: translateY(0px);
  }
  25% {
      opacity: 1;
      transform: translateY(0px);
  }
  30% {
      opacity: 0;
      transform: translateY(5px);
  }
  80% {
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
}

/* Define the fade-up animation */
@keyframes fadeUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.fade-up {
  animation: fadeUp 0.8s ease forwards;
}

@keyframes fadeLeft {
  from {
      opacity: 0;
      transform: translateX(-20px); /* Move the element to the left */
  }
  to {
      opacity: 1;
      transform: translateX(0); /* Move the element to its original position */
  }
}
.fade-left {
  animation: fadeLeft 0.8s ease forwards;
}

@keyframes fadeRight {
  from {
      opacity: 0;
      transform: translateX(20px); /* Move the element to the right */
  }
  to {
      opacity: 1;
      transform: translateX(0); /* Move the element to its original position */
  }
}
.fade-right {
  animation: fadeRight 0.8s ease forwards;
}

@media screen and (max-width: 768px) {
  .wrapper-content {
      flex-direction: column;
      align-items: center;
  }

  .banner {
      width: calc(100% - 40px);
      max-width: none;
  }
}

.invalid-feedback {
  color: #dc3545; /* or any other contrasting color */
  font-weight: bold; /* make the text bold for emphasis */
  background-color: #fff; /* a slightly darker shade of red */
  padding: 5px 10px; /* adjust padding as needed */
  border-radius: 5px; /* add rounded corners for a cleaner look */
}
