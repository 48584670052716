/* App.css */

.App {
  text-align: center;
}
/* Splash screen styles */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 249, 250, 0.5); /* Background color with low opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}


.splash-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo-azm2 {
  width: 50%; /* Responsive width */
  max-width: 400px; /* Maximum width */
  height: auto;
  margin-bottom: 20px; /* Add spacing between logo and loader */
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loader::after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: orangered;
  margin: 0 auto;
  animation: loader 1.2s linear infinite;
}
/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .logo-azm2 {
    width: 40%; /* Adjust width for smaller screens */
  }

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loader {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(20px);
  }
}
